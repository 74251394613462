<template>
  <div id="app">
    <van-nav-bar title="工单详情" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-tabs v-model="active">
      <van-tab title="详情">
        <div class="order-detail">
          <van-form @submit="submitReading">
            <van-collapse v-model="activeName" accordion>
              <van-collapse-item :title="eqName" :name="1"><!--加上disabled就是需要扫码的-->
                <div v-for="(item, index) in eqList" :key="index" class="innerBox">
                  <div style="overflow: hidden">
                    <div style="overflow: hidden">
                      <span class="fl font-black">部位：{{ item.part }}</span>
                      <span hidden class="fr">
                        <van-icon name="photo" size="14" @click="takePhoto(item.id, index, item.eq.id)" />
                      </span>
                    </div>
                    <p class="font-black">方法：{{ item.task.name }}</p>
                    <p class="font-black">内容：{{ item.param }}</p>
                    <p class="font-black">标准：{{ item.param_detail }}</p>
                  </div>
                  <div v-if="item.task.type_id === 0">
                    <van-radio-group v-model="item.reading" direction="horizontal" style="margin: 1px 0 6px;">
                      <van-radio name="1">正常</van-radio>
                      <van-radio name="2">不正常</van-radio>
                    </van-radio-group>
                  </div>
                  <div v-else>
                    <van-field v-model="item.reading" label="读数" placeholder="请输入读数" name="reading" class="innerReading" />
                  </div>
                  <img :src="item.photo" alt="" v-if="item.photo" width="100" height="100">
                </div>
              </van-collapse-item>
            </van-collapse>
            <div v-if="data.status === 1" style="margin: 16px;">
              <van-button round block type="primary" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
      </van-tab>
      <van-tab title="工单">
        <van-pull-refresh v-model="detailLoading" @refresh="onDetailRefresh">
          <van-loading color="#1989fa" class="page-loading" :hidden="pmLoadingHidden" />
          <div class="order-detail" :hidden="pmLoading">
            <van-field v-model="data.admin.name" label="负责人" readonly />
            <van-field v-model="data.eq.name" label="资产" readonly />
            <van-field v-model="data.eq.coding" label="资产编号" readonly />
            <van-field v-model="data.arrange_date" label="安排时间" readonly />
            <van-field v-if="data.complete_time" v-model="data.complete_time" label="完成时间" />
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import API from '@/api/workorder/pm'
import { planType } from '@/utils/options'
import {Dialog, Notify, Toast} from 'vant'
import WxApi from "@/api/wx"
import wx from "weixin-js-sdk"
import {mapGetters} from "vuex";
export default {
  inject: ['backUrl', 'reload', 'showSize', 'goUrl', 'getScope', 'isWorkorderMember'],
  data() {
    return{
      data: {
        admin: {
          name: ''
        }
      },
      eqList: [],
      parts: {
        data: []
      },
      eqName: '',
      eqId: 0,
      eqIds: '',
      activeName: 1,
      active: 1,
      detailLoading: false,
      pmLoadingHidden: false,
      pmLoading: true,
      planType,
      finishWx: false,
      scope: null,
      isMember: false
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created() {
    this.scope = this.getScope(this.userId)
    this.getConfig()
    this.index()
  },
  methods: {
    index() {
      this.pmLoadingHidden = false
      this.pmLoading = true
      const id = this.$route.params.id
      API.getOne(id).then(response => {
        if (response.data.error_code) {
          Dialog.alert({
            title: '错误',
            message: '您请求的工单不存在'
          }).then(() => {
            this.backUrl()
          })
        }

        this.data = response.data
        this.eqName = this.data.eq.name
        this.eqId = this.data.equipment_id
        this.eqList = response.data.other_eq

        if (this.$route.query.eqId) {
          this.eqIds = String(this.eqId)
          // this.activeName = String(this.eqId)
          this.isMember = this.isWorkorderMember(this.data, this.userId)
          if ( this.isMember || this.userId===1 ) {
            this.qrCodeTime()
          }
        }
      }).finally(() => {
        this.pmLoadingHidden = true
        this.pmLoading = false
        this.detailLoading = false
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onDetailRefresh() {
      this.detailLoading = true
      this.showToast = true
      this.index()
    },
    submitReading() {
      const postData = {
        data: Object.assign({},this.eqList),
        id: this.$route.params.id,
        eq: this.data.equipment_id
      }
      API.submitReading(postData).then((response) => {
        if (response.data.error_code === 1) {
          Notify({
            type: 'success',
            message: '恭喜你，提交成功'
          })
          this.goUrl('/workorder/pm1')
        } else if (response.data.error_code === 11) {
          Notify({
            type: 'success',
            message: '参数不正常，已自动生成报修单'
          })
          this.goUrl('/workorder/pm1')
        } else {
          Notify({
            type: 'danger',
            message: response.data.message
          })
        }
      })
    },
    getConfig() {
      const self = this
      let url = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.localStorage.getItem('scanUrl');

      WxApi.wxJSTicket({ url: url }).then((res) => {
        const data = res.data;
        wx.config({
          beta: true,
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: ['chooseImage','uploadImage','getLocalImgData','scanQRCode','checkJsApi'] // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          console.log("成功")
          self.finishWx = true
        })
        wx.error(function (err) {
          console.log(err)
        })
      })
    },
    checkJsApi(api) {
      //检测api是否可用
      return new Promise((rel, err) => {
        wx.checkJsApi({
          jsApiList: [api], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            if (res.checkResult && res.checkResult.chooseImage == false) {
              this.$toast("微信版本较低，请升级后重试！")
              err("版本低")
            } else {
              rel(true)
            }
          }
        })
      })
    },
    takePhoto(id, index, eqId) {
      const self = this
      this.checkJsApi("scanQRCode").then((rel) => {
        if (rel) {
          wx.chooseImage({
            count: 1, // 默认9
            sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
            sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
            success: function (res) {
              const localIds = res.localIds // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
              if(localIds.length>0){
                self.wxUploadPic(localIds, id, index, eqId)
              }
            }
          })
        }
      })
    },
    wxUploadPic(localIds, id, index, eqId){
      const self = this
      this.checkJsApi("scanQRCode").then((rel) => {
        if (rel) {
          wx.getLocalImgData({
            localId: localIds[0].toString(),
            success: function (res) {
              const localData = res.localData
              let imageBase64 = ''
              if (localData.indexOf('data:image') == 0) {
                //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
                imageBase64 = localData
              } else {
                //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
                //此时一个正常的base64图片路径就完美生成赋值到img的src中了
                imageBase64 = 'data:image/jpeg;base64,' + localData.replace(/\n/g, '')
              }
              if (imageBase64) {
                let param = {
                  base64data: imageBase64,
                  id
                }
                console.log(self.showSize(imageBase64))
                API.photo(param).then(res => {
                  self.eqList[eqId][index].v.photo = res.data.img_path
                })
              } else {
                Toast("选择图片出了点问题，请稍后再试下吧");
              }
            }
          })
        }
      })
    },
    qrCodeTime() {
      if (this.data['other_time'].length === 0 && this.data['status'] === 1) {
        API.SelectEqTime({
          'equipment_id': this.data.equipment_id,
          'workorder_id': this.$route.params.id
        }).then(res => {
          console.log(res)
        })
      }
    }
  }
}
</script>

<style scoped>
.order-detail{
  box-sizing: border-box;
  text-align: left;
  padding: 10px 10px 80px;
}
.order-detail .innerBox{
  border-bottom: 1px dashed #999;
  margin-bottom: 10px;
}
.order-detail .innerBox .innerReading{
  margin-bottom: 10px;
  border: 1px solid #afadad;
}
.van-field__control{
  border: 1px solid !important;
  padding-left: 10px;
}
.mb20{
  margin-bottom: 20px;
}
.mr10{
  margin-right: 10px;
}
.font-black{
  color: #000000;
}
</style>
